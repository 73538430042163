<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    >
      <template #title-right>
        <v-blacker-layout align-center>
          <v-blacker-spacer />
          <span
            class="vbt-margin-r-10"
            style="font-size: 17px;"
          >
            All accounts
          </span>
          <v-blacker-switch
            color="info"
            class="custom-search-toggle"
            @change="changeAccountsState"
          />
        </v-blacker-layout>
      </template>
    </vbt-search>

    <vbt-content-box
      :loading="isLoading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <vbt-table
        :headers="headers"
        :items="complaints"
        show-details-action
        @click:details="goToComplaintDetails"
      >
        <template #orderId="{ item: { orderId } }">
          <router-link
            :to="{
              name: 'order',
              params: { id: orderId },
            }"
            class="blue--text darken-1"
            target="_blank"
          >
            {{ orderId }}
          </router-link>
        </template>

        <template #printCustomerId="{ item: { printCustomerId: accId } }">
          {{ accountNameById[accId] }}
        </template>

        <template #printProviderId="{ item: { printProviderId: accId } }">
          {{ accountNameById[accId] }}
        </template>

        <template #created="{ item: { created } }">
          {{ created | formatDate('local', '-') }}
        </template>

        <template #status="{ item: { status } }">
          {{ complaintStatusesByTypeId[status] }}
        </template>

        <template #compensateProduction="{ item: { compensateProduction: _state } }">
          <complaint-state-icon :state="Boolean(_state)" />
        </template>

        <template #compansateShipping="{ item: { compansateShipping: _state } }">
          <complaint-state-icon :state="Boolean(_state)" />
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import ComplaintStateIcon from '../_components/ComplaintStateIcon.vue';

const dataLoadingOptions = { getterName: 'getComplaints' };
const paginationOptions = { itemsName: 'complaints' };
const searchOptions = {
  initialSearchDataStates: [
    'status', 'reference', 'printCustomerId', 'printProviderId',
  ],
  customTypes: {
    status: 'number',
    printCustomerId: 'number',
    printProviderId: 'number',
  },
};

const headers = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Order Item ID', value: 'orderItemId' },
  { text: 'Customer', value: 'printCustomerId' },
  { text: 'Provider', value: 'printProviderId' },
  { text: 'Created Date', value: 'created' },
  { text: 'Status', value: 'status' },
  { text: 'Reason', value: 'reason' },
  { text: 'Compensate Production', value: 'compensateProduction' },
  { text: 'Compensate Shipping', value: 'compansateShipping' },
]);

export default {
  name: 'IrRefunds',

  components: {
    ComplaintStateIcon,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState('refunds', ['complaints']),

    ...mapState('enums', {
      complaintStatusSelectOptions: ({ complaintStatuses: items }) => (items || [])
        .map(({ value, name: text }) => ({ value, text })),
    }),

    ...mapGetters('enums', ['complaintStatusesByTypeId']),
    ...mapGetters('accounts', ['clientsOptions', 'providersOptions', 'accountNameById']),

    searchFieldsConfig() {
      return [
        {
          value: 'status',
          items: this.complaintStatusSelectOptions,
          label: 'Status',
          type: 'combobox',
          flex: 'sm3',
        },
        {
          value: 'printCustomerId',
          items: this.clientsOptions,
          label: 'Client',
          type: 'combobox',
          flex: 'sm3',
        },
        {
          value: 'printProviderId',
          items: this.providersOptions,
          label: 'Provider',
          type: 'combobox',
          flex: 'sm3',
        },
        {
          value: 'reference',
          label: 'Reference',
          type: 'text',
          flex: 'sm3',
        },
      ];
    },
  },

  created() {
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('refunds', ['getComplaints']),

    ...mapActions('accounts', ['getAccounts']),

    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },

    goToComplaintDetails({ id }) {
      this.$router.push({ name: 'refund', params: { id } });
    },
  },
};
</script>
